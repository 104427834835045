<template>
    <div class="container pb-5">
        <div class="section-heading text-center mb-5">
            <h1 class="mb-2 fw-bold">COIN Official Channel</h1>
            <p class="text-pale">Join to Follow COIN Latest News!</p>
        </div>
        <div class="sns_box">
            <div class="d-flex">
                <div class="title bg-primary">
                    <img src="@/assets/img/icon/twitter_icon_white.svg" alt="twitter">
                    <span class="fw-bold">Twitter</span>
                </div>
                <div class="address">
                    <span><a href="https://twitter.com/">https://twitter.com/</a></span>
                    <span class="copy text-main">Copy</span>
                </div>
            </div>
            <div class="d-flex">
                <div class="title bg-primary">
                    <img src="@/assets/img/icon/medium_icon_white.svg" alt="Medium">
                    <span class="fw-bold">Medium</span>
                </div>
                <div class="address">
                    <span><a href="https://medium.com/">https://medium.com/</a></span>
                    <span class="copy text-main">Copy</span>
                </div>
            </div>
            <div class="d-flex">
                <div class="title bg-primary">
                    <img src="@/assets/img/icon/telegram_icon_white.svg" alt="telegram">
                    <span class="fw-bold">Telegram</span>
                </div>
                <div class="address">
                    <span><a href="https://telegram.com/">https://telegram.com/</a></span>
                    <span class="copy text-main">Copy</span>
                </div>
            </div>
            <div class="d-flex">
                <div class="title bg-primary">
                    <img src="@/assets/img/icon/discord_icon_white.svg" alt="Discord">
                    <span class="fw-bold">Discord</span>
                </div>
                <div class="address">
                    <span><a href="https://discord.com/">https://discord.com/</a></span>
                    <span class="copy text-main">Copy</span>
                </div>
            </div>
            <div class="d-flex">
                <div class="title bg-primary">
                    <img src="@/assets/img/icon/reddit_icon_white.svg" alt="reddit">
                    <span class="fw-bold">Reddit</span>
                </div>
                <div class="address">
                    <span><a href="https://reddit.com/">https://reddit.com/</a></span>
                    <span class="copy text-main">Copy</span>
                </div>
            </div>
            <div class="d-flex">
                <div class="title bg-primary">
                    <img src="@/assets/img/icon/youtube_icon_white.svg" alt="youtube">
                    <span class="fw-bold">Youtube</span>
                </div>
                <div class="address">
                    <span><a href="https://youtube.com/">https://youtube.com/</a></span>
                    <span class="copy text-main">Copy</span>
                </div>
            </div>
        </div>

        <div class="section-heading text-center mt-5 mb-5 pt-5">
            <h1 class="mb-2 fw-bold">Multilingual Telegram Community</h1>
            <p class="text-pale">Join Our Global Communities</p>
        </div>
        <div>
            <ul class="global_list">
                <li v-for="(item,index) in globalList" :key="index">
                    <a href="#" class="d-flex flex-wrap">
                        <img :src="require('@/assets/img/flag/'+item.code+'_icon.svg')" :alt="item.title+' telegram'">
                        <div>
                            <p>{{item.title}}</p>
                            <p class="small text-pale">{{item.address}}</p>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            globalList: [
                {
                    title: "Global Group",
                    code: "en",
                    address: "https://t.me/coin_official",
                },
                {
                    title: "Korean Group",
                    code: "ko",
                    address: "https://t.me/coin_official",
                },
                {
                    title: "Japanese Group",
                    code: "jp",
                    address: "https://t.me/coin_official",
                },
                {
                    title: "Chinese Group",
                    code: "ch",
                    address: "https://t.me/coin_official",
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .copy{
        cursor: pointer;
    }
    .sns_box{
        &>div{
            box-shadow: 0 4px 15px rgba($color: #000, $alpha: 0.1);
            margin: 0 0 20px;
            border-radius: 10px;
            overflow: hidden;
        }
        .title{
            display: flex;
            align-items: center;
            color: #fff;
            width: 320px;
            padding: 16px;
            text-align: left;
            font-size: 20px;

            img{
                width: 40px;
                margin-right: 20px;
            }

            span{
                line-height: 40px;
                margin-bottom: 5px;
            }
        }

        .address{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            padding: 0 30px;
            width: 100%;
        }
    }

    .global_list{
        display: flex;
        flex-wrap: wrap;
        gap: 2%;

        li{
            width: 23%;
            margin-bottom: 2%;
            text-align: left;
            border-radius: 10px;
            box-shadow: 0 4px 15px rgba($color: #000, $alpha: 0.1);
            transition: all 0.2s;

            a{
                padding: 16px;
            }

            img{
                width: 40px;
                margin-right: 20px;
            }

            &:hover{
                transform: translateY(-5px);
            }
        }
    }
    @media screen and (max-width:768px) {
        
        .sns_box{
            &>div{
                flex-wrap: wrap;
            }
            .title{
                padding: 8px 16px;
                width: 100%;
            }

            .address{
                padding: 25px 30px;
            }
        }

        .global_list{
            gap: 0;
            
            li{
                width: 100%;
            }
        }

    }
</style>